import React from "react";
import { Td, Tbody, Tr, Flex, Text } from "@chakra-ui/react";

const StockMovementReportTable = ({ data }) => {
  return (
    <Tbody>
      {data?.map((item, key) => {
        return (
          <Tr key={key}>
            <Td>
              <Flex>
                <Text>{item?.name}</Text>&nbsp;&nbsp;
                <Text>{`(${item?.product_id})`}</Text>
              </Flex>
            </Td>
            <Td>{item?.sku}</Td>
            <Td>{parseInt(item?.sold_qty)}</Td>
            <Td>{parseInt(item?.stock)}</Td>
            <Td>{parseInt(item?.rol)}</Td>
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default StockMovementReportTable;
