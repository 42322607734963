/**
 * Dynamic rgb color generation
 */
const dynamicColors = function () {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};
/**
 * Generate a list of colors
 * @param {Array<*>} data list
 */
const generateColorList = (data) => {
  var coloR = [];

  for (var i in data) {
    coloR.push(dynamicColors());
  }
  return coloR;
};
export default generateColorList;
