import axios from "axios";

let API_URL;
let MAGENTO_API_URL;

if (window.location.origin === "https://www.stag-mis.nesto.shop") {
  API_URL = "https://staging-api.nesto.shop";
  MAGENTO_API_URL = "https://staging.nesto.shop/rest/V1";
} else if (window.location.origin === "https://www.mis.nesto.shop") {
  API_URL = "https://api.nesto.shop";
  MAGENTO_API_URL = "https://nesto.shop/rest/V1";
} else if (window.location.origin === "https://www.preprod-mis.nesto.shop") {
  API_URL = "https://preprod-api.nesto.shop";
  MAGENTO_API_URL = "https://preprod.nesto.shop/rest/V1";
} else {
  API_URL = "https://staging-api.nesto.shop";
  MAGENTO_API_URL = "https://staging.nesto.shop/rest/V1";
}

const DEFAULT_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const axiosInstanceMagento = axios.create({
  baseURL: MAGENTO_API_URL,
});

const networkErrorLogger = (e, URL, PAYLOAD, isAuth = true) => {
  console.log(
    `Request: ${URL} with payload: ${JSON.stringify(PAYLOAD)} failed! `
  );

  if (e.message === "Network Error") {
    throw new Error("Network Error. Ensure you are connected to internet.");
  } else if (e.response) {
    if (isAuth && e.response?.status === 401) {
      window.localStorage.removeItem("auth");
      window.location.reload();
    }
    throw e.response;
  } else {
    throw e;
  }
};

const setUpConfig = (isAuth) => {
  console.log("Config updated version");

  const DEFAULT_CONFIG = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (isAuth) {
    const access_token = window.localStorage.getItem("auth");
    DEFAULT_CONFIG.headers["access-token"] = access_token;
    return DEFAULT_CONFIG;
  } else {
    return DEFAULT_CONFIG;
  }
};

const get = async (
  URL,
  getFullData = false,
  magento = false,
  isAuth = true
) => {
  try {
    const CONFIG = setUpConfig(isAuth);
    const result = magento
      ? await axiosInstanceMagento.get(URL, CONFIG)
      : await axiosInstance.get(URL, CONFIG);

    return getFullData ? result.data : result.data.data;
  } catch (e) {
    networkErrorLogger(e, URL, "nil");
  }
};

const post = async (URL, PAYLOAD = {}, isAuth, getFullData = false) => {
  try {
    const CONFIG = setUpConfig(false);
    const result = await axiosInstance.post(URL, PAYLOAD, CONFIG);
    return getFullData ? result.data : result.data.data;
  } catch (e) {
    networkErrorLogger(e, URL, PAYLOAD, isAuth);
  }
};

export { post, get };
