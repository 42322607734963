import React from "react";
import { Td, Tbody, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";

const OrderTimelineReportTable = ({ data }) => {
  return (
    <Tbody>
      {data?.map((item, key) => {
        return (
          <Tr key={key}>
            <Td>{item?.timeslot}</Td>
            <Td>{item?.express_orders}</Td>
            <Td>{item?.online_payment_orders}</Td>
            <Td>{item?.pay_on_delivery_orders}</Td>
            <Td>{item?.scheduled_orders}</Td>
            <Td>{item?.total_orders}</Td>
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default OrderTimelineReportTable;
