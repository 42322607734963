import { Button, useColorMode } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

import { defaults } from "react-chartjs-2";
import { useEffect } from "react";

const ToggleTheme = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    if (colorMode === "light") defaults.color = "#000";
    else defaults.color = "#fff";
  }, [colorMode]);
  return (
    <Button onClick={toggleColorMode} size="sm">
      {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
    </Button>
  );
};

export default ToggleTheme;
