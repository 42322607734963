import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ chartData, height }) => {
  const data = {
    labels: chartData?.xData || [],
    datasets: chartData?.yData || []
  };

  const options = {
    interaction: {
      mode: "index"
    },
    scales: chartData?.optionScale || {
      y: {
        position: "left",
        title: {
          display: true,
          text: "Number of bills",
          font: {
            size: 15
          }
        },
        grid: {
          drawOnChartArea: false // only want the grid lines for one axis to show up
        }
      }
    },
    maintainAspectRatio: true
  };
  return <Line data={data} options={options} height={height} />;
};

export default LineChart;
