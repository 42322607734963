import {
  Thead,
  Tr,
  Th,
  Box,
  Flex,
  Text,
  Button,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react"; //Table Imports
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { RiArrowUpDownFill } from "react-icons/ri";

//table heads
export const SalesTableHead = () => (
  <Thead>
    <Tr>
      <Th>Order ID</Th>
      <Th>Delivery Date</Th>
      <Th>Order Status</Th>
      <Th>SO Number</Th>
      <Th>SO Invoice Number</Th>
      <Th>Order Value</Th>
      <Th>Order Value with Tax</Th>
      <Th>Payment Reference</Th>
      <Th>Purchase Date</Th>
      <Th>Returned Date</Th>
      <Th>Returned Value with Tax</Th>
      <Th>SAP Invoice Date</Th>
    </Tr>
  </Thead>
);

export const AgentSummaryTableHead = () => (
  <Thead>
    <Tr>
      <Th>Agent Id</Th>
      <Th>Agent Name</Th>
      <Th>Date</Th>
      <Th>Vehicle Number</Th>
      <Th>Card difference</Th>
      <Th>Card received</Th>
      <Th>card refunded</Th>
      <Th>Card sales</Th>
      <Th>Cash difference</Th>
      <Th>Cash received</Th>
      <Th>Cash refunded</Th>
      <Th>Cash sales</Th>
      <Th>Delivery fee received</Th>
      <Th>Difference</Th>
      <Th>Net sales</Th>
      <Th>Payment gateway difference</Th>
      <Th>Payment gateway received</Th>
      <Th>Payment gateway refunded</Th>
      <Th>Payment gateway sales</Th>
      <Th>Returned total</Th>
      <Th>Total collection</Th>
      <Th>Total Sales</Th>
    </Tr>
  </Thead>
);

export const AgentOrdersTableHead = () => (
  <Thead>
    <Tr textTransform="uppercase">
      <Th>Id</Th>
      <Th>Reservation Guid</Th>
      <Th>Status</Th>
      <Th>card difference</Th>
      <Th>card received</Th>
      <Th>card refunded</Th>
      <Th>card sales</Th>
      <Th>cash difference</Th>
      <Th>cash received</Th>
      <Th>cash refunded</Th>
      <Th>cash sales</Th>
      <Th>cod type</Th>
      <Th>delivery date</Th>
      <Th>delivery fee</Th>
      <Th>order total</Th>
      <Th>order type</Th>
      <Th>payment mode</Th>
      <Th>payment ref</Th>
      <Th>pg difference</Th>
      <Th>pg received</Th>
      <Th>pg refunded</Th>
      <Th>pg sales</Th>
      <Th>purchase date</Th>
      <Th>return method</Th>
      <Th>return pickup</Th>
      <Th>return type</Th>
      <Th>sales incremental id</Th>
      <Th>sales order id</Th>
    </Tr>
  </Thead>
);

export const AgentSummaryTableHead_v1 = () => (
  <Thead>
    <Tr>
      <Th>Agent Name</Th>
      <Th>Vehicle</Th>
      <Th>Card difference</Th>
      <Th>Card received</Th>
      <Th>Card sales</Th>
      <Th>Cash difference</Th>
      <Th>Cash received</Th>
      <Th>Cash sales</Th>
      <Th>Difference</Th>
      <Th>Extra amount</Th>
      <Th>Net sales</Th>
      <Th>Payment gateway difference</Th>
      <Th>Payment gateway received</Th>
      <Th>Payment gateway sales</Th>
      <Th>Total collection</Th>
      <Th>Total return value</Th>
      <Th>Total sales</Th>
    </Tr>
  </Thead>
);

export const AgentOrdersTableHead_v1 = () => (
  <Thead>
    <Tr>
      <Th>Agent Id</Th>
      <Th>Agent Name</Th>
      <Th>Date</Th>
      <Th>Cod type</Th>
      <Th>Credit</Th>
      <Th>Debit</Th>
      <Th>Delivery date</Th>
      <Th>Extra amount</Th>
      <Th>Order total</Th>
      <Th>Payment gateway credit amount</Th>
      <Th>Payment reference</Th>
      <Th>Payment type</Th>
      <Th>Placed date</Th>
      <Th>Return method</Th>
      <Th>Return pickup</Th>
      <Th>Return type</Th>
      <Th>Returned value</Th>
      <Th>Sales incremental id</Th>
      <Th>Sales order id</Th>
      <Th>Sap invoice date</Th>
    </Tr>
  </Thead>
);
export const SalesSummaryTableHead = () => (
  <Thead>
    <Tr>
      <Th>Name</Th>
      <Th>Card sales count</Th>
      <Th>Card sales total</Th>
      <Th>Cash sales count</Th>
      <Th>Cash sales total</Th>
      <Th>Online sales count</Th>
      <Th>online sales total</Th>
      <Th>Total order count</Th>
      <Th>Total sales</Th>
      <Th>Total sales without tax</Th>
    </Tr>
  </Thead>
);

export const LocationTableHead = () => (
  <Thead>
    <Tr>
      <Th>Order Id</Th>
      <Th>Name</Th>
      <Th>Email</Th>
      <Th>Emirate</Th>
      <Th>Delivered time</Th>
      <Th>Latitude</Th>
      <Th>Longitude</Th>
      <Th>Timeslot</Th>
    </Tr>
  </Thead>
);

export const TenderSalesDataHead = () => (
  <Thead>
    <Tr>
      <Th>Agent</Th>
      <Th>Counted amount</Th>
      <Th>Desc</Th>
      <Th>Difference</Th>
      <Th>Transaction amount</Th>
    </Tr>
  </Thead>
);

export const TenderSalesReportHead = () => (
  <Thead>
    <Tr>
      <Th>Type</Th>
      <Th>Counted amount</Th>
      <Th>Difference</Th>
      <Th>Transaction amount</Th>
    </Tr>
  </Thead>
);

export const ManageWalletsTableHead = () => (
  <Thead>
    <Tr>
      <Th>ID</Th>
      <Th>Date</Th>
      <Th>Vehicle Number</Th>
      <Th>Delivery Agent</Th>
      <Th>Cash Uploaded</Th>
      <Th>Cash Received</Th>
      <Th>Cash Refunded</Th>
      <Th>Wallet Balance</Th>
      <Th>Accepted Amount</Th>
      <Th>Closure Balance</Th>
      <Th>Excess/Shortage</Th>
      <Th>Remarks</Th>
      <Th>Closure </Th>
    </Tr>
  </Thead>
);

export const ManageWalletTransactionsTableHead = ({
  color,
  size,
  walletName,
  link,
  textColor,
  showButton
}) => {
  const smallTextColor = useColorModeValue("#474A57", "#e5e5e5");
  return (
    <Thead mb="10px">
      <Tr>
        <Th border="none">
          <Flex alignItems="center" mb="20px">
            <Box
              borderRadius="50%"
              w="9px"
              h="9px"
              background={color}
              border="none"
              mr="2"
            ></Box>
            <Text fontSize={size} color={textColor}>
              {walletName}
            </Text>
          </Flex>
          <Text
            color={smallTextColor}
            fontWeight="400"
            fontSize="15px"
            mb="20px"
          >
            Recent Transactions
          </Text>
        </Th>
        <Th d="flex" justifyContent="flex-end" border="none">
          {showButton && (
            <Link to={link}>
              <Button alignItems="center" borderRadius="18px" color="#22923F">
                {"View All"}
                <ChevronRightIcon w="25px" h="25px" color="#22923F" />
              </Button>
            </Link>
          )}
        </Th>
      </Tr>
    </Thead>
  );
};

export const ReconciliationLhsTableHead = () => (
  <Thead>
    <Tr>
      <Th>Excess Cash Recieved</Th>
      <Th>Net Sales</Th>
      <Th>Output Tax Value</Th>
      <Th>Total Return Sales</Th>
      <Th>Total Sales</Th>
    </Tr>
  </Thead>
);
export const ReconciliationRhsTableHead = () => (
  <Thead>
    <Tr>
      <Th>Shortage Cash Refund</Th>
      <Th>Tender Card Total</Th>
      <Th>Tender Cash Total</Th>
      <Th>Tender Master Card Total</Th>
      <Th>Tender Other Card Total</Th>
      <Th>Tender Payment Gateway Total</Th>
      <Th>Tender Visa Card Total</Th>
    </Tr>
  </Thead>
);
export const MissingReportTableHead = () => (
  <Thead>
    <Tr>
      <Th>Date</Th>
      <Th>Site</Th>
    </Tr>
  </Thead>
);
export const SearchResultTableHead = () => (
  <Thead>
    <Tr>
      <Th>Site</Th>
      <Th>Date</Th>
      <Th>Document Number</Th>
      <Th>Report Closed</Th>
    </Tr>
  </Thead>
);
export const GeneralTableHead = ({ headers, sortKey, sort }) => (
  <Thead>
    <Tr>
      {headers.map((header, key) => (
        <Th
          key={`key-${key}`}
          onClick={() => header.isSortable && sort(header)}
          cursor={header.isSortable ? "pointer" : ""}
        >
          <Flex>
            {header.name}
            {header.id === sortKey && header.isSortable ? (
              <FaSortDown />
            ) : (
              header.isSortable && <RiArrowUpDownFill />
            )}
          </Flex>
        </Th>
      ))}
    </Tr>
  </Thead>
);
export const TopSlowMovingProductsTableHead = ({ isdescending, sort }) => (
  <Thead>
    <Tr>
      <Th>Sku</Th>
      <Th>Name</Th>
      <Th>Total Sold</Th>
      <Th onClick={sort} cursor="pointer">
        <Flex>Sales Value{isdescending ? <FaSortDown /> : <FaSortUp />}</Flex>
      </Th>
    </Tr>
  </Thead>
);
export const OrderAndCancellationTableHead = () => (
  <Thead>
    <Tr>
      <Th>Order Date</Th>
      <Th>Delivered</Th>
      <Th>Cancelled</Th>
      <Th>Return</Th>
      <Th>Out for Delivery</Th>
      <Th>Packing Completed</Th>
      <Th>Packing Initiated</Th>
      <Th>Pending</Th>
      <Th>Picking Completed</Th>
      <Th>Picking Initiated</Th>
      <Th>Ready for delivery</Th>
      <Th>Return collected</Th>
      <Th>return initiated</Th>
      <Th>Total Orders</Th>
    </Tr>
  </Thead>
);
export const NOBReportTableHead = () => (
  <Thead>
    <Tr>
      <Th>Date</Th>
      <Th>Number of bills</Th>
      <Th>Average transaction value</Th>
    </Tr>
  </Thead>
);
export const CouponReportTableHead = () => (
  <Thead>
    <Tr>
      <Th>Coupon Code</Th>
      <Th>Discount Amount</Th>
      <Th>Number of orders</Th>
      <Th>Total discount amount</Th>
      <Th>Total order value</Th>
    </Tr>
  </Thead>
);
export const OrderTimelineReportTableHead = () => (
  <Thead>
    <Tr>
      <Th>Timeslot</Th>
      <Th>Express Orders</Th>
      <Th>Online Payment Orders</Th>
      <Th>Pay On Delivery Orders</Th>
      <Th>Scheduled Orders</Th>
      <Th>Total Orders</Th>
    </Tr>
  </Thead>
);
export const StockMovementReportTableHead = () => (
  <Thead>
    <Tr>
      <Th>Product (Product Id)</Th>
      <Th>Quantity Sold</Th>
      <Th>Total Stock</Th>
      <Th>Reorder Limit</Th>
    </Tr>
  </Thead>
);
