import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableCaption,
  Flex,
  useToast,
  Box,
  Select,
  Button,
  Badge,
  Input,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";

import { GeneralTableHead } from "../components/TableHeads";
import CategoryPerformanceTable from "../containers/CategoryPerformanceTable";
import DoughnutChart from "../components/DoughnutChart";

import { exportToExcel } from "../utils/exportToExcel";

import { getCategoryReport, getSites } from "../api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import generateColor from "../utils/dynamicColorGeneration";
import sort from "../utils/sort";

import Pagination from "../components/Pagination";
import { getSitesFromPermissions } from "../utils/getSitesFromPermissions";

dayjs.extend(isToday);
/**
 * category report (performance/sales)
 */
function CategoryPerformanceReport(props) {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const defaultSiteID = params.get("id");
  const [emptyLoading, setemptyLoading] = useState(false);
  const [report, setreport] = useState([]);
  const [filterReport, setfilterReport] = useState([]);
  const [sites, setSites] = useState([]);
  const [level, setLevel] = useState([3]);
  const [selectedSiteID, setselectedSiteID] = useState(defaultSiteID);
  const [color, setcolor] = useState([]);
  var start_date = new Date();
  start_date.setMonth(start_date.getMonth() - 1);
  const [startDate, setstartDate] = useState(start_date);
  const [endDate, setendDate] = useState(new Date());
  const [totalSales, settotalSales] = useState({});
  const [sortKey, setsortKey] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowToShow, setRowToShow] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  let tableHeaders = [
    {
      id: "category_id",
      name: "CATEGORY ID",
      isSortable: false,
      type: "number",
    },
    { id: "name", name: "NAME", isSortable: true, type: "string" },
    {
      id: "total_qty",
      name: "TOTAL QUANTITY",
      isSortable: true,
      type: "number",
    },
    {
      id: "total_sales",
      name: "TOTAL SALES",
      isSortable: true,
      type: "number",
    },
  ];
  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategory = async () => {
    setemptyLoading(true);
    const response = await getCategoryReport(
      selectedSiteID,
      dayjs(startDate).format("YYYY-MM-DD"),
      dayjs(endDate).format("YYYY-MM-DD")
    );
    setreport(response?.categories);
    setemptyLoading(false);
  };
  useEffect(() => {
    let total_sales = [],
      category_names = [],
      tempReport = [];
    level?.map((levelId) => {
      tempReport = [
        ...tempReport,
        ...report?.filter((catItem) => catItem?.level === levelId?.toString()),
      ];
    });
    // setfilterReport(tempReport);
    tempReport.forEach((item) => {
      total_sales.push(item.total_sales);
      category_names.push(item.name);
    });
    settotalSales({ total_sales: total_sales, category_names: category_names });
    setcolor(generateColor(total_sales));
    handleSort({ id: "total_sales", type: "number" }, tempReport);
  }, [report, level]);

  useEffect(() => {
    getCategory();
  }, [selectedSiteID, startDate, endDate]);

  /**
   * search filter functionality
   */
  const searchFilter = (value) => {
    setfilterReport(
      report.filter((item) => {
        return item.category_id.toString().includes(value.toLowerCase().trim());
      })
    );
  };

  //pagination refresh
  useEffect(() => {
    setTotalPages(Math.ceil(filterReport?.length / rowToShow) ?? 0);
    setCurrentPage(1);
  }, [rowToShow, filterReport]);

  /**
   * handle sort in tables
   * @param {object} header
   */
  const handleSort = (header, report = filterReport) => {
    let sorted_array = sort(header.id, header.type, report);
    setfilterReport(sorted_array);
    setsortKey(header.id);
  };
  /**
   * download functionality 📩
   */
  const onDownloadClick = async () => {
    try {
      exportToExcel(
        "category_report",
        report,
        `Category Performance Report`,
        "Category Performance Report"
      );
    } catch (e) {
      console.log(e);
      Toast("Failed to generate report", e?.data?.message ?? "", "error");
    } finally {
    }
  };
  const onButtonClick = (e) => {
    e.preventDefault();
    // Show a toast when user is trying to download todays report
    // if (dayjs(selectedDate).isToday()) {
    //   Toast(
    //     "Possible Incomplete Report",
    //     "Note: Report could be incomplete as the day is not yet over.",
    //     "info"
    //   );
    // }
    onDownloadClick();
  };
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      //   disabled={isLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));
  /**
   * toast functionality ⏰
   */

  const Toast = (title = "", desc = "", type) =>
    toast({
      title: title,
      description: desc,
      status: type,
      duration: 3000,
      isClosable: true,
    });

  const handleLevels = (lvlID) => {
    if (level?.filter((levelID) => levelID === lvlID)?.length !== 0) {
      if (level?.filter((levelID) => levelID !== lvlID)?.length === 0)
        setLevel([3]);
      else setLevel(level?.filter((levelID) => levelID !== lvlID));
    } else setLevel([...level, lvlID]);
  };

  return (
    <Flex direction="column">
      <Flex direction="column">
        <Box width="185px" mr="20px" my="10px">
          <Badge variant="subtle" fontSize="16px" colorScheme={"twitter"}>
            {`Category Report (performance/sales)`}
          </Badge>
        </Box>
        <Flex
          width={"100%"}
          wrap="wrap"
          justifyContent="space-between"
          my="10px"
        >
          <Select
            variant="filled"
            // placeholder={sites?.length ? "Select site" : "No sites found"}
            value={selectedSiteID}
            onChange={(e) => {
              setselectedSiteID(e.target.value);
            }}
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            {getSitesFromPermissions().map((site) => (
              <option value={site} key={site}>
                {site}
              </option>
            ))}
          </Select>
          <Flex>
            <Box my="10px" mr="20px">
              <DatePicker
                selected={startDate}
                maxDate={startDate > endDate ? endDate : new Date()}
                onChange={(date) => {
                  setstartDate(date);
                }}
                dateFormat="PP"
                title={"From"}
                customInput={<CustomDatePicker />}
                todayButton="Select today"
                showPopperArrow={false}
                popperPlacement="bottom"
                // disabled={isLoading}
              />
            </Box>

            <Box my="10px" mr="20px">
              <DatePicker
                selected={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={(date) => {
                  setendDate(date);
                }}
                dateFormat="PP"
                title={"To"}
                customInput={<CustomDatePicker />}
                todayButton="Select today"
                showPopperArrow={false}
                popperPlacement="bottom"
                // disabled={isLoading}
              />
            </Box>
          </Flex>
          <Button
            size="sm"
            isLoading={emptyLoading}
            disabled={emptyLoading || !selectedSiteID || !sites?.length}
            rightIcon={<DownloadIcon />}
            onClick={onButtonClick}
            my="10px"
          >
            Download
          </Button>
          <Input
            placeholder="search"
            size="sm"
            my="10px"
            mr="20px"
            width="350px"
            onChange={(e) => searchFilter(e.target.value)}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="center">
        <DoughnutChart
          data={totalSales.total_sales}
          labels={totalSales.category_names}
          islegandVisible={false}
          color={color}
        />
      </Flex>
      <Flex overflowX="auto">
        <Table variant="simple" size="sm">
          <TableCaption placement={"top"} textAlign="left">
            <Flex justifyContent="space-between">
              <Text>{`Category Report (performance/sales)`}</Text>
              <Box>
                <Button
                  size="sm"
                  mx="1"
                  color={
                    level?.filter((levelId) => levelId === 3).length !== 0
                      ? "white"
                      : "black"
                  }
                  backgroundColor={
                    level?.filter((levelId) => levelId === 3).length !== 0
                      ? "#178571"
                      : "lightgrey"
                  }
                  onClick={() => handleLevels(3)}
                >
                  Main Categories
                </Button>
                <Button
                  size="sm"
                  mx="1"
                  color={
                    level?.filter((levelId) => levelId === 4).length !== 0
                      ? "white"
                      : "black"
                  }
                  backgroundColor={
                    level?.filter((levelId) => levelId === 4).length !== 0
                      ? "#178571"
                      : "lightgrey"
                  }
                  onClick={() => handleLevels(4)}
                >
                  Sub Categories - Lvl 1
                </Button>
                <Button
                  size="sm"
                  mx="1"
                  color={
                    level?.filter((levelId) => levelId === 5).length !== 0
                      ? "white"
                      : "black"
                  }
                  backgroundColor={
                    level?.filter((levelId) => levelId === 5).length !== 0
                      ? "#178571"
                      : "lightgrey"
                  }
                  onClick={() => handleLevels(5)}
                >
                  Sub Categories - Lvl 2
                </Button>
              </Box>
            </Flex>
          </TableCaption>
          <GeneralTableHead
            headers={tableHeaders}
            sortKey={sortKey}
            sort={handleSort}
          />
          <CategoryPerformanceTable
            report={
              filterReport?.slice(
                (currentPage - 1) * rowToShow,
                currentPage * rowToShow
              ) ?? []
            }
            emptyLoading={emptyLoading}
          />
        </Table>
      </Flex>
      <Pagination
        page={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        rowToShow={rowToShow}
        setRowToShow={setRowToShow}
      />
    </Flex>
  );
}
export default CategoryPerformanceReport;
