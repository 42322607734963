import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Flex,
  useToast,
  Box,
  Select,
  Button,
  Badge,
  Text,
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";

import { HubLevelSkeleton } from "../components/TableSkeletons";

import { exportToExcel } from "../utils/exportToExcel";

import { getHubLevelReport, getSites } from "../api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

import LineChart from "../components/LineChart.js";
import BarChart from "../components/BarChart";
import { getSitesFromPermissions } from "../utils/getSitesFromPermissions";

dayjs.extend(isToday);
/**
 * Hub level reports
 * - Date wise placed orders
 * - Date wise returns
 * - Date wise cancellations
 * - Date wise sales
 * - Average transaction value
 * - Timeslot wise sales
 */
function HubLevelReport(props) {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const siteId = params.get("site_id");
  const storeId = params.get("store_id");

  const [emptyLoading, setemptyLoading] = useState(false);
  const [report, setreport] = useState({});
  const [sites, setSites] = useState([]);

  const [selectedId, setselectedId] = useState({
    storeId: storeId,
    siteId: siteId,
  });
  const [selectedSiteId, setselectedSiteId] = useState(
    JSON.stringify({
      store_id: storeId,
      website_id: siteId,
    })
  );

  var start_date = new Date();
  start_date.setMonth(start_date.getMonth() - 1);
  const [startDate, setstartDate] = useState(start_date);
  const [endDate, setendDate] = useState(new Date());

  //charts data managing states
  const [placedOrdersChartData, setplacedOrdersChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {},
  });
  const [returnsChartData, setreturnsChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {},
  });
  const [cancellationChartData, setcancellationChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {},
  });
  const [salesChartData, setsalesChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {},
  });
  const [timeslotSalesChartData, settimeslotSalesChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {},
  });
  const [averageTransactionChartData, setaverageTransactionChartData] =
    useState({
      xData: [],
      yData: [],
      optionScale: {},
    });

  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategory = async () => {
    setemptyLoading(true);
    const response = await getHubLevelReport(
      selectedId.storeId,
      selectedId.siteId,
      dayjs(startDate).format("YYYY-MM-DD"),
      dayjs(endDate).format("YYYY-MM-DD")
    );
    setemptyLoading(false);
    setreport(response);
  };
  useEffect(() => {
    getCategory();
  }, [selectedSiteId, startDate, endDate]);

  // date_wise_placed_orders

  useEffect(() => {
    let date = [],
      total_count = [],
      total_value = [];
    report?.date_wise_placed_orders?.map((item) => {
      date.push(dayjs(item.date).format("DD/MM/YYYY"));
      total_count.push(item.total_count);
      total_value.push(item.total_value);
    });
    setplacedOrdersChartData({
      xData: date,

      yData: [
        {
          label: "Number of Orders",
          data: total_count,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "y1",
        },
        {
          label: "Total Value",
          data: total_value,
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
      ],
      optionScale: {
        y: {
          position: "left",
          title: {
            display: true,
            text: "Total Value",
            font: {
              size: 13,
            },
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        y1: {
          position: "right",
          title: {
            display: true,
            text: "Number of Orders",
            font: {
              size: 13,
            },
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        x: {
          position: "bottom",
        },
      },
    });
  }, [report]);

  // date_wise_returns

  useEffect(() => {
    let date = [],
      order_count = [];
    report?.date_wise_returns?.map((item) => {
      date.push(dayjs(item.date).format("DD/MM/YYYY"));
      order_count.push(item.order_count);
    });
    setreturnsChartData({
      xData: date,

      yData: [
        {
          label: "Number of Returns",
          data: order_count,
          backgroundColor: ["rgba(54, 255, 160, 0.2)"],
          borderColor: ["rgba(54, 255, 160, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
      ],
      optionScale: {
        x: {
          position: "bottom",
        },
      },
    });
  }, [report]);

  // date_wise_cancellations

  useEffect(() => {
    let date = [],
      order_count = [];
    report?.date_wise_cancellations?.map((item) => {
      date.push(dayjs(item.date).format("DD/MM/YYYY"));
      order_count.push(item.order_count);
    });
    setcancellationChartData({
      xData: date,

      yData: [
        {
          label: "Number of Cancellations",
          data: order_count,
          backgroundColor: ["rgba(255, 0, 180, 0.2)"],
          borderColor: ["rgba(255, 0, 180, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
      ],
      optionScale: {
        x: {
          position: "bottom",
        },
      },
    });
  }, [report]);

  // date_wise_sales

  useEffect(() => {
    let date = [],
      pay_on_delivery_sales = [],
      payment_gateway_sales = [],
      scheduled_sales = [],
      total_count = [],
      total_sales = [],
      express_sales = [];
    report?.date_wise_sales?.map((item) => {
      date.push(dayjs(item.date).format("DD/MM/YYYY"));
      express_sales.push(item.express_sales);
      payment_gateway_sales.push(item.payment_gateway_sales);
      scheduled_sales.push(item.scheduled_sales);
      pay_on_delivery_sales.push(item.pay_on_delivery_sales);
      total_count.push(item.total_count);
      total_sales.push(item.total_sales);
    });
    setsalesChartData({
      xData: date,

      yData: [
        {
          label: "Express sales",
          data: express_sales,
          backgroundColor: ["rgba(153, 102, 255, 0.2)"],
          borderColor: ["rgba(153, 102, 255, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Payment gateway sales",
          data: payment_gateway_sales,
          backgroundColor: ["rgba(26, 75, 255, 0.2)"],
          borderColor: ["rgba(26, 75, 255, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Payment on Delivery",
          data: pay_on_delivery_sales,
          backgroundColor: ["rgba(255, 75, 255, 0.2)"],
          borderColor: ["rgba(255, 75, 255, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Scheduled sales",
          data: scheduled_sales,
          backgroundColor: ["rgba(255, 0, 180, 0.2)"],
          borderColor: ["rgba(255, 0, 180, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Total invoice created",
          data: total_sales,
          backgroundColor: ["rgba(100,0, 50, 0.2)"],
          borderColor: ["rgba(100,0, 50, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Number of sales",
          data: total_count,
          backgroundColor: ["rgba(26, 255, 60, 0.2)"],
          borderColor: ["rgba(26, 255, 60, 1)"],
          borderWidth: 1,
          yAxisID: "y1",
        },
      ],
      optionScale: {
        y: {
          position: "left",
          title: {
            display: true,
            text: "",
            font: {
              size: 13,
            },
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        y1: {
          position: "right",
          title: {
            display: true,
            text: "Number of sales",
            font: {
              size: 13,
            },
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        x: {
          position: "bottom",
        },
      },
    });
  }, [report]);

  // Average Transaction Value

  useEffect(() => {
    let date = [],
      atv_per_day = [];
    report?.date_wise_sales?.map((item) => {
      date.push(dayjs(item.date).format("DD/MM/YYYY"));
      atv_per_day.push(item.atv_per_day);
    });
    setaverageTransactionChartData({
      xData: date,

      yData: [
        {
          label: "Average Transaction Value",
          data: atv_per_day,
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
      ],
      optionScale: {
        x: {
          position: "bottom",
        },
      },
    });
  }, [report]);

  // timeslot_wise_sales

  useEffect(() => {
    let timeslot_with_date = [],
      total_count = [],
      atv = [],
      total_sales = [];
    report?.timeslot_wise_sales?.map((item) => {
      let time = item.timeslot_with_date.split(" ");
      timeslot_with_date.push(
        dayjs(time[0]).format("DD/MM/YYYY") + " " + time[1]
      );
      total_count.push(item.total_count);
      atv.push(item.atv);
      total_sales.push(item.total_sales);
    });
    settimeslotSalesChartData({
      xData: timeslot_with_date,

      yData: [
        {
          label: "Average Transaction Value",
          data: atv,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Total Sales Value",
          data: total_sales,
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Number of sales",
          data: total_count,
          backgroundColor: ["rgba(54, 255, 160, 0.2)"],
          borderColor: ["rgba(54, 255, 160, 1)"],
          borderWidth: 1,
          yAxisID: "y1",
        },
      ],
      optionScale: {
        y: {
          position: "left",
          title: {
            display: true,
            text: "Average Transaction Value/Total sales",
            font: {
              size: 10,
            },
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        y1: {
          position: "right",
          title: {
            display: true,
            text: "Number of sales",
            font: {
              size: 10,
            },
          },
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
        x: {
          position: "bottom",
        },
      },
    });
  }, [report]);

  /**
   * Update site_id and store_id
   * @param {string} value
   */
  const updateSite = (value) => {
    setselectedId({
      siteId: JSON.parse(value)?.website_id,
      storeId: JSON.parse(value)?.store_id,
    });
    setselectedSiteId(value);
  };

  /**
   * download functionality 📩
   */
  const onDownloadClick = async () => {
    try {
      exportToExcel(
        "hub_level",
        report,
        `Hub Level Report`,
        "Hub Level Report"
      );
    } catch (e) {
      console.log(e);
      Toast("Failed to generate report", e?.data?.message ?? "", "error");
    } finally {
    }
  };
  const onButtonClick = (e) => {
    e.preventDefault();
    onDownloadClick();
  };
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      //   disabled={emptyLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));
  /**
   * toast functionality ⏰
   * @param {string} title
   * @param {string} desc
   * @param {string} type
   */
  const Toast = (title = "", desc = "", type) =>
    toast({
      title: title,
      description: desc,
      status: type,
      duration: 3000,
      isClosable: true,
    });

  return (
    <Flex direction="column">
      <Flex direction="column">
        <Box width="185px" mr="20px" my="10px">
          <Badge variant="subtle" fontSize="16px" colorScheme={"twitter"}>
            HUB LEVEL REPORT
          </Badge>
        </Box>
        <Flex
          width={"100%"}
          wrap="wrap"
          justifyContent="space-between"
          my="10px"
        >
          <Select
            variant="filled"
            // placeholder={sites?.length ? "Select site" : "No sites found"}
            value={selectedSiteId}
            onChange={(e) => {
              updateSite(e?.target?.value);
            }}
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            {getSitesFromPermissions().map((site) => (
              <option value={site} key={site}>
                {site}
              </option>
            ))}
          </Select>
          <Flex>
            <Box my="10px" mr="20px">
              <DatePicker
                selected={startDate}
                maxDate={startDate > endDate ? endDate : new Date()}
                onChange={(date) => {
                  setstartDate(date);
                }}
                dateFormat="PP"
                title={"From"}
                customInput={<CustomDatePicker />}
                todayButton="Select today"
                showPopperArrow={false}
                popperPlacement="bottom"
                // disabled={isLoading}
              />
            </Box>
            <Box my="10px" mr="20px">
              <DatePicker
                selected={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={(date) => {
                  setendDate(date);
                }}
                dateFormat="PP"
                title={"To"}
                customInput={<CustomDatePicker />}
                todayButton="Select today"
                showPopperArrow={false}
                popperPlacement="bottom"
                // disabled={isLoading}
              />
            </Box>{" "}
          </Flex>
          <Button
            size="sm"
            isLoading={emptyLoading}
            disabled={emptyLoading || !selectedSiteId || !sites?.length}
            rightIcon={<DownloadIcon />}
            onClick={onButtonClick}
            my="10px"
          >
            Download
          </Button>
        </Flex>
      </Flex>
      {emptyLoading && <HubLevelSkeleton />}
      {!emptyLoading && (
        <Flex justifyContent="space-between">
          <Flex direction="column">
            <Text fontWeight="500">Average Transaction Value</Text>
            <Text color="red.500">{`AED ${report?.hub_atv ?? 0}`}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontWeight="500">Total express sales</Text>
            <Text color="red.500">{`AED ${
              report?.total_express_sales ?? 0
            }`}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontWeight="500">Pay on delivery sales</Text>
            <Text color="red.500">{`AED ${
              report?.total_pay_on_delivery_sales ?? 0
            }`}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontWeight="500">Payment gateway sales</Text>
            <Text color="red.500">{`AED ${
              report?.total_payment_gateway_sales ?? 0
            }`}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontWeight="500">Scheduled sales</Text>
            <Text color="red.500">{`AED ${
              report?.total_scheduled_sales ?? 0
            }`}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontWeight="500">Total invoice created</Text>
            <Text color="red.500">{`AED ${report?.total_sales ?? 0}`}</Text>
          </Flex>
        </Flex>
      )}
      <Flex justifyContent="space-around" my={20}>
        <Box width={"48%"}>
          <Text fontWeight="medium" marginBottom="3px">
            Placed Orders
          </Text>
          <LineChart chartData={placedOrdersChartData} height={"90px"} />
        </Box>
        <Box width={"48%"}>
          <Text fontWeight="medium" marginBottom="3px">
            Date wise Sales
          </Text>
          <LineChart chartData={salesChartData} height={"90px"} />
        </Box>
      </Flex>
      <Flex justifyContent="space-around">
        <Box width={"38%"}>
          <Text fontWeight="medium" marginBottom="3px">
            Average Transaction Value
          </Text>
          <BarChart chartData={averageTransactionChartData} height={"90px"} />
        </Box>

        <Box width={"58%"}>
          <Text fontWeight="medium" marginBottom="3px">
            Timeslot wise sales
          </Text>
          <LineChart chartData={timeslotSalesChartData} height={"90px"} />
        </Box>
      </Flex>
      <Flex justifyContent="space-around">
        <Box width={"48%"}>
          <Text fontWeight="medium" marginBottom="3px">
            Returns
          </Text>
          <BarChart chartData={returnsChartData} height={"90px"} />
        </Box>
        <Box width={"48%"}>
          <Text fontWeight="medium" marginBottom="3px">
            Cancellations
          </Text>
          <BarChart chartData={cancellationChartData} height={"90px"} />
        </Box>
      </Flex>
    </Flex>
  );
}
export default HubLevelReport;
