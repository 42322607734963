import { removeRefreshToken, getRefreshToken } from './token';

export function setupAxios(axios, toast, logOutUser) {
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (err) {
			const originalRequest = err.config;
			if (
				err.response.status === 401 &&
				originalRequest.url.includes('token')
			) {
				removeRefreshToken();
				logOutUser();
				window.location.replace('/');
				toast({
					description: err.response?.data?.message || 'An error occurred',
					status: 'error',
				});
				return Promise.reject(err);
			}
			if (err.response?.status === 401 && getRefreshToken()) {
				const result = await axios.post(
					`${process.env.REACT_APP_UMS_BASE_URL}/admin/token`,
					{ refreshToken: getRefreshToken() }
				);
				window.localStorage.setItem('auth', result.data.data.token);
				originalRequest.headers['access-token'] = result.data.data.token;
				return axios(originalRequest);
			}
			return Promise.reject(err);
		}
	);
}
