import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons"; //Icon Imports
import { Button, Box, Select, Flex } from "@chakra-ui/react"; //Table Imports

/**
 * pagination for tables
 */
const Pagination = ({
  page,
  setCurrentPage,
  totalPages,
  setRowToShow,
  rowToShow,
  rowOptions = [10, 20, 30, 50],
}) => {
  const showLeftPageButton = page !== 1;
  const showRightPageButton = page !== totalPages;
  const previousPage = () => {
    setCurrentPage(page - 1);
  };

  const nextPage = () => {
    setCurrentPage(page + 1);
  };

  return (
    <Flex justifyContent="space-between" mt="5">
      <Box>
        <Button
          onClick={() => {
            setCurrentPage(1);
          }}
          isDisabled={page === 1}
          size="sm"
          mx="2"
        >
          <ArrowLeftIcon />
        </Button>
        <Button onClick={previousPage} isDisabled={page === 1} size="sm" mx="2">
          <ArrowBackIcon />
        </Button>
      </Box>
      <Box>
        {showLeftPageButton && (
          <Button onClick={previousPage} size="sm" mx="2">
            {page - 1}
          </Button>
        )}
        <Button isDisabled={true} colorScheme="green" size="sm" mx="2">
          {page}
        </Button>
        {showRightPageButton && (
          <Button onClick={nextPage} size="sm" mx="2">
            {page + 1}
          </Button>
        )}
      </Box>
      <Box>
        <Flex>
          <Button
            onClick={nextPage}
            isDisabled={page === totalPages}
            size="sm"
            mx="2"
          >
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={() => {
              setCurrentPage(totalPages);
            }}
            isDisabled={page === totalPages}
            size="sm"
            mx="2"
          >
            <ArrowRightIcon />
          </Button>
          <Select
            size="sm"
            mx="2"
            value={rowToShow}
            onChange={(e) => {
              setRowToShow(e.target.value);
            }}
          >
            {rowOptions?.map((rowoption) => (
              <option value={rowoption} key={`key-${rowoption}`}>
                {rowoption}
              </option>
            ))}
          </Select>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Pagination;
