import { getUser } from "./token";

export const getSitesFromPermissions = () => {
  try {
    const perms = getUser();
    let sitesPermitted = perms
      .filter((p) => p.code === "MIS_ACCESS")[0]
      .subPermission.filter((p) => p.is_read)
      .map((p) => p.site_id);
    return sitesPermitted;
  } catch (err) {
    return [];
  }
};
