import React from "react";
import {
  chakra,
  Heading,
  HStack,
  Image,
  Spacer,
  useColorModeValue,
  Tooltip,
  Button,
  Flex,
  Icon,
  useBreakpointValue,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerOverlay,
  DrawerFooter,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import ToggleTheme from "../components/ToggleTheme";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { IoMdPower } from "react-icons/io";
import { FcDocument } from "react-icons/fc";
import logo from "../assets/logo.svg";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { RiWallet3Line, RiMistFill } from "react-icons/ri";
import { HamburgerIcon } from "@chakra-ui/icons";
import reportList from "../contants/reportList";

const Header = () => {
  const location = useLocation();
  const bg = useColorModeValue("white", "gray.800");
  const { isLoggedIn, logOutUser } = useContext(AuthContext);
  const history = useHistory();
  const headingText = useBreakpointValue({
    base: "",
    sm: "MIS",
    md: "MIS Console",
    lg: "Management Information System",
  });
  /**
   * logout
   */
  const logoutUser = () => {
    logOutUser();
    console.log("should replace");
    window.location.replace(process.env.REACT_APP_ADMIN_PANEL_URL);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <chakra.header
      shadow={"xs"}
      transition="box-shadow 0.2s"
      pos="fixed"
      top="0"
      zIndex="3"
      left="0"
      right="0"
      bg={bg}
      width="full"
      borderBottom="1px"
      borderColor="gray.200"
    >
      <HStack px={10}>
        {location?.pathname !== "/" && (
          <>
            <HamburgerIcon ref={btnRef} onClick={onOpen} cursor="pointer" />
            <Drawer
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton color="black" />
                <DrawerHeader color="red" fontWeight="900" fontSize="24">
                  Reports
                </DrawerHeader>
                <DrawerBody>
                  {reportList?.map((report, key) => (
                    <Flex
                      my="2"
                      alignItems="center"
                      key={`${report?.default_id}-${key}`}
                    >
                      <Icon as={FcDocument} w={8} h={8} mr="3" />
                      <Box
                        cursor="pointer"
                        onClick={() => {
                          onClose();
                          history.push(
                            `${report?.url}?name=${report?.selectID}${
                              report?.default_id
                                ? `&id=${report?.default_id}`
                                : ``
                            }${
                              report?.default_web_id
                                ? `&store_id=${report?.default_web_id?.store}&site_id=${report?.default_web_id?.site}`
                                : ``
                            }`
                          );
                        }}
                      >
                        {report?.reportName}
                      </Box>
                    </Flex>
                  ))}
                </DrawerBody>
                <DrawerFooter></DrawerFooter>
              </DrawerContent>
            </Drawer>
            {/* <Menu>
              <MenuList>
              
              </MenuList>
            </Menu> */}
          </>
        )}
        <Link to="/">
          <HStack>
            <Image
              height="50px"
              objectFit="contain"
              src={logo}
              alt="Nesto Logo"
              borderRadius={10}
              backgroundColor={"white"}
            />
          </HStack>
        </Link>
        <Spacer />
        <Heading py={5} size="md">
          {headingText}
        </Heading>
        <Spacer />
        <Link to="/kpi-stats">
          <Button
            background="#178571"
            size="sm"
            color="#ffffff"
            leftIcon={<RiMistFill color="#ffffff" size="20" />}
          >
            KPI Stats
          </Button>
        </Link>
        <ToggleTheme />
        {isLoggedIn && (
          <Tooltip hasArrow label={"Log out"} aria-label={"Log out"}>
            <Button onClick={logoutUser} size="sm">
              <IoMdPower />
            </Button>
          </Tooltip>
        )}
      </HStack>
    </chakra.header>
  );
};

export default Header;
