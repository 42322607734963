import { Box, Center, Progress } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setRefreshToken } from "../utils/token";

export default function Login() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    const tryLogin = async () => {
      const token = query.get("token") || localStorage.getItem("refresh_token");
      if (!token) {
        window.location.replace(process.env.REACT_APP_ADMIN_PANEL_URL);
        return;
      }
      setRefreshToken(token);
    };
    tryLogin();
  }, []);

  return (
    <Box px={10}>
      <Center sx={{ height: "100vh" }}>
        <Progress
          mt={100}
          width={500}
          size="xs"
          isIndeterminate
          colorScheme="green"
        />
      </Center>
    </Box>
  );
}
