import React from "react";
import { Td, Tbody, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";

const NOBReportTable = ({ data }) => {
  return (
    <Tbody>
      {data?.map((item, key) => {
        return (
          <Tr key={key}>
            <Td>{dayjs(Object.keys(item)[0]).format("DD-MM-YYYY")}</Td>
            <Td>{Object.values(item)[0]?.nob}</Td>
            <Td>{Object.values(item)[0]?.atv}</Td>
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default NOBReportTable;
