import { Tbody, Td, Text, Tr } from "@chakra-ui/react"; //Table Imports
import { OrderStatusSkeleton } from "../components/TableSkeletons";
import dayjs from "dayjs";
// import MapsLink from "../components/MapsLink";
// import ItemsTotal from "./ItemsTotal";
// import OrderDetails from "./OrderDetails";

const OrderStatusTable = ({ emptyLoading, report }) => {
  return (
    <>
      {emptyLoading && <OrderStatusSkeleton />}
      {!emptyLoading && report && (
        <Tbody>
          {report.map((item, index) => {
            const order_date =
              dayjs(item.order_date).format("DD/MM/YYYY") ?? "-";
            const delivered_count = item.delivered_count ?? "-";
            const cancelled_count = item.cancelled_count ?? "-";
            const return_count = item.return_count ?? "-";
            const out_for_delivery_count = item.out_for_delivery_count ?? "-";
            const packing_completed_count = item.packing_completed_count ?? "-";
            const packing_initiated_count = item.packing_initiated_count ?? "-";
            const pending_count = item.pending_count ?? "-";
            const picking_completed_count = item.picking_completed_count ?? "-";
            const picking_initiated_count = item.picking_initiated_count ?? "-";
            const ready_for_delivery_count =
              item.ready_for_delivery_count ?? "-";
            const return_collected_count = item.return_collected_count ?? "-";
            const return_initiated_count = item.return_initiated_count ?? "-";
            const total_orders_count = item.total_orders_count ?? "-";

            return (
              <Tr key={`${order_date}-${index}`}>
                <Td minWidth={100}>
                  <Text>{order_date}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{delivered_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{cancelled_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{return_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{out_for_delivery_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{packing_completed_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{packing_initiated_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{pending_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{picking_completed_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{picking_initiated_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{ready_for_delivery_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{return_collected_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{return_initiated_count}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{total_orders_count}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export default OrderStatusTable;
