import { Tbody, Td, Text, Tr } from "@chakra-ui/react"; //Table Imports
import { TopSlowMovingProductsSkeleton } from "../components/TableSkeletons";
// import MapsLink from "../components/MapsLink";
// import ItemsTotal from "./ItemsTotal";
// import OrderDetails from "./OrderDetails";

const TopSlowMovingProductsTable = ({ emptyLoading, report }) => {
  return (
    <>
      {emptyLoading && <TopSlowMovingProductsSkeleton />}
      {!emptyLoading && report && (
        <Tbody>
          {report.map((item, index) => {
            const sku = item?.sku ?? "-";
            const name = item?.name ?? "-";
            const qty_sold = item?.qty_sold ?? "-";
            const sales_value = item?.sales_value ?? "-";

            return (
              <Tr key={`${sku}-${index}`}>
                <Td minWidth={100}>
                  <Text>{sku}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{name}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{qty_sold}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{sales_value}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export default TopSlowMovingProductsTable;
