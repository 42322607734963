import { Tbody, Td, Text, Tr } from "@chakra-ui/react"; //Table Imports
import { SalesSkeleton } from "../components/TableSkeletons";
// import MapsLink from "../components/MapsLink";
// import ItemsTotal from "./ItemsTotal";
// import OrderDetails from "./OrderDetails";

const SalesTableBody = ({ emptyLoading, report }) => {
  return (
    <>
      {emptyLoading && <SalesSkeleton />}
      {!emptyLoading && report && (
        <Tbody>
          {report.map((item, index) => {
            const id = item?.["Order ID"] ?? "-";
            const delivery_date = item?.["Delivery Date"] ?? "-";
            const order_status = item?.["Order Status"] ?? "-";
            const order_value = item?.["Order Value"] ?? "-";
            const order_value_with_tax = item?.["Order Value with Tax"] ?? "-";
            const payment_reference = item?.["Payment Reference"] ?? "-";
            const purchase_date = item?.["Purchase Date"] ?? "-";
            const returned_date = item?.["Returned Date"] ?? "-";
            const returned_value_with_tax =
              item?.["Returned Value with Tax"] ?? "-";
            const sAP_invoice_date = item?.["SAP Invoice Date"] ?? "-";
            const sO_invoice_number = item?.["SO Invoice Number"] ?? "-";
            const sO_number = item?.["SO Number"] ?? "-";

            return (
              <Tr key={`${id}-${index}`}>
                <Td minWidth={150}>
                  <Text>{id}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{delivery_date}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{order_status}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{sO_number}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{sO_invoice_number}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{order_value}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{order_value_with_tax}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{payment_reference}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{purchase_date}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{returned_date}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{returned_value_with_tax}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{sAP_invoice_date}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export default SalesTableBody;
