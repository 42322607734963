import { Tbody, Td, Text, Tr } from "@chakra-ui/react"; //Table Imports
import {
  AgentSummarySkeleton,
  AgentOrdersSkeleton,
} from "../components/TableSkeletons";
// import MapsLink from "../components/MapsLink";
// import ItemsTotal from "./ItemsTotal";
// import OrderDetails from "./OrderDetails";

const AgentSummaryReportTable = ({ emptyLoading, report }) => {
  return (
    <>
      {emptyLoading && <AgentSummarySkeleton />}
      {!emptyLoading && report && (
        <Tbody>
          {report.map((item, index) => {
            let agent_name,
              vehicle,
              card_difference,
              card_received,
              card_sales,
              cash_difference,
              cash_received,
              cash_sales,
              difference,
              extra_amount,
              net_sales,
              payment_gateway_difference,
              payment_gateway_received,
              payment_gateway_sales,
              total_collection,
              total_return_value,
              total_sales;
            if (item.type === "sum") {
              agent_name = "sum";
              vehicle = "-";
              card_difference = item?.report?.card_difference ?? "-";
              card_received = item?.report?.card_received ?? "-";
              card_sales = item?.report?.card_sales ?? "-";
              cash_difference = item?.report?.cash_difference ?? "-";
              cash_received = item?.report?.cash_received ?? "-";
              cash_sales = item?.report?.cash_sales ?? "-";
              difference = item?.report?.difference ?? "-";
              extra_amount = item?.report?.extra_amount ?? "-";
              net_sales = item?.report?.net_sales ?? "-";
              payment_gateway_difference =
                item?.report?.payment_gateway_difference ?? "-";
              payment_gateway_received =
                item?.report?.payment_gateway_received ?? "-";
              payment_gateway_sales =
                item?.report?.payment_gateway_sales ?? "-";
              total_collection = item?.report?.total_collection ?? "-";
              total_return_value = item?.report?.total_return_value ?? "-";
              total_sales = item?.report?.total_sales ?? "-";
            } else {
              agent_name = item?.agent_name ?? "-";
              vehicle = item?.vehicle ?? "-";
              card_difference = item?.card_difference ?? "-";
              card_received = item?.card_received ?? "-";
              card_sales = item?.card_sales ?? "-";
              cash_difference = item?.cash_difference ?? "-";
              cash_received = item?.cash_received ?? "-";
              cash_sales = item?.cash_sales ?? "-";
              difference = item?.difference ?? "-";
              extra_amount = item?.extra_amount ?? "-";
              net_sales = item?.net_sales ?? "-";
              payment_gateway_difference =
                item?.payment_gateway_difference ?? "-";
              payment_gateway_received = item?.payment_gateway_received ?? "-";
              payment_gateway_sales = item?.payment_gateway_sales ?? "-";
              total_collection = item?.total_collection ?? "-";
              total_return_value = item?.total_return_value ?? "-";
              total_sales = item?.total_sales ?? "-";
            }
            return (
              <Tr key={`${index}`}>
                <Td minWidth={200}>
                  <Text>{agent_name}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{vehicle}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{card_difference}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{card_received}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{card_sales}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{cash_difference}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{cash_received}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{cash_sales}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{difference}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{extra_amount}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{net_sales}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{payment_gateway_difference}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{payment_gateway_received}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{payment_gateway_sales}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{total_collection}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{total_return_value}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{total_sales}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

const AgentOrdersTable = ({ emptyLoading, report }) => {
  console.log(report);
  return (
    <>
      {emptyLoading && <AgentOrdersSkeleton />}
      {!emptyLoading && report.orders && (
        <Tbody>
          {report.orders.map((item, index) => {
            const id = report?.agentId ?? "-";
            const agentName = report?.agentName ?? "-";
            const date = report?.date ?? "-";
            const cod_type = item?.cod_type ?? "-";
            const credit = item?.credit ?? "-";
            const debit = item?.debit ?? "-";
            const delivery_date = item?.delivery_date ?? "-";
            const extra_amount = item?.extra_amount ?? "-";
            const order_total = item?.order_total ?? "-";
            const payment_gateway_credit_amount =
              item?.payment_gateway_credit_amount ?? "-";
            const payment_reference = item?.payment_reference ?? "-";
            const payment_type = item?.payment_type ?? "-";
            const placed_date = item?.placed_date ?? "-";
            const return_method = item?.return_method ?? "-";
            const return_pickup = item.return_pickup ? "true" : "false";
            const return_type = item?.return_type ?? "-";
            const returned_value = item?.returned_value ?? "-";
            const sales_incremental_id = item?.sales_incremental_id ?? "-";
            const sales_order_id = item?.sales_order_id ?? "-";
            const sap_invoice_date = item?.sap_invoice_date ?? "-";
            return (
              <Tr key={`${id}-${index}`}>
                <Td minWidth={100}>
                  <Text>{id}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{agentName}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{date}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{cod_type}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{credit}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{debit}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{delivery_date}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{extra_amount}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{order_total}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{payment_gateway_credit_amount}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{payment_reference}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{payment_type}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{placed_date}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{return_method}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{return_pickup}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{return_type}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{returned_value}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{sales_incremental_id}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{sales_order_id}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{sap_invoice_date}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export { AgentSummaryReportTable, AgentOrdersTable };
