import dayjs from "dayjs";
import { get, post } from "./networkUtils";

var AUTH_BASE_URL;
if (window.location.origin === "https://www.stag-mis.nesto.shop") {
  AUTH_BASE_URL = "https://auth.nesto.shop/staging";
} else if (window.location.origin === "https://www.mis.nesto.shop") {
  AUTH_BASE_URL = "https://auth.nesto.shop";
} else if (window.location.origin === "https://www.preprod-mis.nesto.shop") {
  AUTH_BASE_URL = "https://auth.nesto.shop/preprod";
} else {
  AUTH_BASE_URL = "https://auth.nesto.shop/dev";
}

export const getSalesReport = async (site, filters) => {
  let { startDate, endDate, limit, page } = filters;
  startDate = dayjs(startDate).format("YYYY-MM-DD");
  endDate = dayjs(endDate).format("YYYY-MM-DD");
  const URL = `/extras/sales-report?source=mis&site=${site}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`;
  return get(URL);
};
// summary report version 1
export const getEndOfDayReport = async (site, date) => {
  const URL = `/extras/end-report-excel/${site}?date=${date}&source=mis`;
  return get(URL);
};

export const getLocationReport = async (site, date) => {
  const URL = `extras/location/report?date=${date}&site_id=${site}&source=mis`;
  return get(URL);
};

export const getKPIData = async (site, startDate, endDate) => {
  const URL = `picker-packer/kpi-statistics?start_date=${startDate}&end_date=${endDate}&site_id=${site}&source=mis`;
  return get(URL);
};

export const login = (PAYLOAD) => {
  // const URL = AUTH_BASE_URL + "/session/start?identifier=mis";
  const URL = 'https://ums-stag.nesto.shop/api/v1/admin/login';
  return post(URL, PAYLOAD, false);
};

export const getSites = async () => {
  const URL = `/mis/sites`;
  return get(URL);
};

export const getVehicleList = async (siteId) => {
  const URL = `/vehiclelist/${siteId}`;
  return get(URL, true, true);
};

export const getDeliveryAgentList = async (siteId) => {
  const URL = `/employeeslist/${siteId}/deliver_agent`;
  return get(URL, true, true);
};

export const getSitesFromLambda = async () => {
  const URL = `/mis/sites`;
  return get(URL, false, false, false);
};

export const getNobAtvReport = async (store_id, start_date, end_date) => {
  const URL = `/reports/nob-atv-report?start_date=${start_date}&end_date=${end_date}&store_id=${store_id}`;
  return get(URL, false, false, true);
};

export const getOrderTimelineReport = async (site_id, start_date, end_date) => {
  const URL = `/reports/order/timeline?site=${site_id}&start_date=${start_date}&end_date=${end_date}`;
  return get(URL, false, false, true);
};

export const getCategoryReport = async (store_id, start_date, end_date) => {
  const URL = `/reports/category/all?store_id=${store_id}&start_date=${start_date}&end_date=${end_date}`;
  return get(URL, false, false, true);
};
export const getOrderStatusRatio = async (store_id, start_date, end_date) => {
  const URL = `/reports/order-status/ratio?store_id=${store_id}&start_date=${start_date}&end_date=${end_date}`;
  return get(URL, false, false, true);
};
export const getStockMovementReport = async (
  store_id,
  start_date,
  end_date
) => {
  const URL = `/reports/stock-movement-report?start_date=${start_date}&end_date=${end_date}&store_id=${store_id}`;
  return get(URL, false, false, true);
};
export const getCouponReport = async (store_id, start_date, end_date) => {
  const URL = `/reports/coupon?store=${store_id}&start_date=${start_date}&end_date=${end_date}`;
  return get(URL, false, false, true);
};
export const getHubLevelReport = async (
  store_id,
  site_id,
  start_date,
  end_date
) => {
  const URL = `/reports/hub?store=${store_id}&site=${site_id}&start_date=${start_date}&end_date=${end_date}`;
  return get(URL, false, false, true);
};

// prod//
export const getProdSites = async () => {
  const URL = `https://api.nesto.shop/mis/sites`;
  return get(URL);
};

export const getProdSalesReport = async (date, site) => {
  const URL = `https://api.nesto.shop/extras/sales-report?date=${date}&source=mis&site=${site}`;
  return get(URL);
};
