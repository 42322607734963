import React, { useState, useEffect, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableCaption,
  Flex,
  useToast,
  Box,
  Select,
  Button,
  Badge,
} from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon } from "@chakra-ui/icons";

import { GeneralTableHead } from "../components/TableHeads";
import OrderStatusTable from "../containers/OrderStatusTable";

import { exportToExcel } from "../utils/exportToExcel";

import { getOrderStatusRatio, getSites } from "../api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";

import LineChart from "../components/LineChart.js";

import sort from "../utils/sort";

import Pagination from "../components/Pagination";
import { getSitesFromPermissions } from "../utils/getSitesFromPermissions";

dayjs.extend(isToday);
/**
 * Order status report
 */
function OrdersAndCancellationReport(props) {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const defaultSiteID = params.get("id");

  const [emptyLoading, setemptyLoading] = useState(false);
  const [filterReport, setfilterReport] = useState([]);
  const [sites, setSites] = useState([]);
  const [sortKey, setsortKey] = useState(true);

  const [selectedSiteID, setselectedSiteID] = useState(defaultSiteID);

  var start_date = new Date();
  start_date.setMonth(start_date.getMonth() - 1);
  const [startDate, setstartDate] = useState(start_date);
  const [endDate, setendDate] = useState(new Date());

  const [currentPage, setCurrentPage] = useState(1);
  const [rowToShow, setRowToShow] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [chartData, setChartData] = useState({
    xData: [],
    yData: [],
    optionScale: {},
  });
  let tableHeaders = [
    {
      id: "order_date",
      name: "ORDER DATE",
      isSortable: true,
      type: "date",
    },
    {
      id: "delivered_count",
      name: "DELIVERED",
      isSortable: true,
      type: "number",
    },
    {
      id: "cancelled_count",
      name: "CANCELLED",
      isSortable: true,
      type: "number",
    },
    {
      id: "return_count",
      name: "RETURN",
      isSortable: true,
      type: "number",
    },
    {
      id: "out_for_delivery_count",
      name: "OUT FOR DELIVERY",
      isSortable: true,
      type: "number",
    },
    {
      id: "packing_completed_count",
      name: "PACKING COMPLETED",
      isSortable: true,
      type: "number",
    },
    {
      id: "packing_initiated_count",
      name: "PACKING INITIATED",
      isSortable: true,
      type: "number",
    },
    {
      id: "pending_count",
      name: "PENDING",
      isSortable: true,
      type: "number",
    },
    {
      id: "picking_completed_count",
      name: "PICKING COMPLETED",
      isSortable: true,
      type: "number",
    },
    {
      id: "picking_initiated_count",
      name: "PICKING INITIATED",
      isSortable: true,
      type: "number",
    },
    {
      id: "ready_for_delivery_count",
      name: "READY FOR DELIVERY",
      isSortable: true,
      type: "number",
    },
    {
      id: "return_collected_count",
      name: "RETURN COLLECTED",
      isSortable: true,
      type: "number",
    },
    {
      id: "return_initiated_count",
      name: "RETURN INITIATED",
      isSortable: true,
      type: "number",
    },
    {
      id: "total_orders_count",
      name: "TOTAL ORDERS",
      isSortable: true,
      type: "number",
    },
  ];
  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategory = async () => {
    let orders = [];
    setemptyLoading(true);
    const response = await getOrderStatusRatio(
      selectedSiteID,
      dayjs(startDate).format("YYYY-MM-DD"),
      dayjs(endDate).format("YYYY-MM-DD")
    );
    setemptyLoading(false);
    Object.keys(response?.orderData).map((key, index) => {
      orders.push(response?.orderData[key][0]);
    });
    setfilterReport(orders);
  };
  useEffect(() => {
    getCategory();
  }, [selectedSiteID, startDate, endDate]);

  useEffect(() => {
    const order_date = [],
      cancelled_count = [],
      delivered_count = [],
      out_for_delivery_count = [],
      packing_completed_count = [],
      packing_initiated_count = [],
      pending_count = [],
      picking_completed_count = [],
      picking_initiated_count = [],
      ready_for_delivery_count = [],
      return_collected_count = [],
      return_initiated_count = [],
      return_count = [];
    filterReport.map((item) => {
      order_date.push(dayjs(item.order_date).format("DD/MM/YYYY"));
      cancelled_count.push(item.cancelled_count);
      delivered_count.push(item.delivered_count);
      out_for_delivery_count.push(item.out_for_delivery_count);
      packing_completed_count.push(item.packing_completed_count);
      packing_initiated_count.push(item.packing_initiated_count);
      pending_count.push(item.pending_count);
      picking_completed_count.push(item.picking_completed_count);
      picking_initiated_count.push(item.picking_initiated_count);
      ready_for_delivery_count.push(item.ready_for_delivery_count);
      return_collected_count.push(item.return_collected_count);
      return_initiated_count.push(item.return_initiated_count);
      return_count.push(item.return_count);
    });
    setChartData({
      xData: order_date,

      yData: [
        {
          label: "Cancelled",
          data: cancelled_count,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Delivered",
          data: delivered_count,
          backgroundColor: ["rgba(54, 162, 235, 0.2)"],
          borderColor: ["rgba(54, 162, 235, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Out for Delivery",
          data: out_for_delivery_count,
          backgroundColor: ["rgba(255, 206, 86, 0.2)"],
          borderColor: ["rgba(255, 206, 86, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Packing Completed",
          data: packing_completed_count,
          backgroundColor: ["rgba(75, 192, 192, 0.2)"],
          borderColor: ["rgba(75, 192, 192, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Packing Initiated",
          data: packing_initiated_count,
          backgroundColor: ["rgba(153, 102, 255, 0.2)"],
          borderColor: ["rgba(153, 102, 255, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Ready for Delivery",
          data: ready_for_delivery_count,
          backgroundColor: ["rgba(26, 75, 255, 0.2)"],
          borderColor: ["rgba(26, 75, 255, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Picking Completed",
          data: picking_completed_count,
          backgroundColor: ["rgba(255, 0, 180, 0.2)"],
          borderColor: ["rgba(255, 0, 180, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Picking Initiated",
          data: picking_initiated_count,
          backgroundColor: ["rgba(26, 255, 60, 0.2)"],
          borderColor: ["rgba(26, 255, 60, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Return Collected",
          data: return_collected_count,
          backgroundColor: ["rgba(100,0, 50, 0.2)"],
          borderColor: ["rgba(100,0, 50, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Return Initiated",
          data: return_initiated_count,
          backgroundColor: ["rgba(100,50, 190, 0.2)"],
          borderColor: ["rgba(100,50, 190, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
        {
          label: "Return",
          data: return_count,
          backgroundColor: ["rgba(200, 0, 150, 0.2)"],
          borderColor: ["rgba(200, 0, 150, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },

        {
          label: "Pending",
          data: pending_count,
          backgroundColor: ["rgba(50, 190, 70, 0.2)"],
          borderColor: ["rgba(50, 190, 70, 1)"],
          borderWidth: 1,
          yAxisID: "y",
        },
      ],
      optionScale: {
        x: {
          position: "bottom",
        },
      },
    });
  }, [filterReport]);

  //   const searchFilter = (value) => {
  //     setfilterReport(
  //       report.filter((item) => {
  //         return item.category_id.toString().includes(value.toLowerCase().trim());
  //       })
  //     );
  //   };

  //pagination refresh
  useEffect(() => {
    setTotalPages(Math.ceil(filterReport?.length / rowToShow) ?? 0);
    setCurrentPage(1);
  }, [rowToShow, filterReport]);

  // sort
  const handleSort = (header) => {
    let sorted_array = sort(header.id, header.type, filterReport);
    setfilterReport(sorted_array);
    setsortKey(header.id);
  };

  // download functionality 📩
  const onDownloadClick = async () => {
    try {
      exportToExcel(
        "order_status",
        filterReport,
        `Order Status Report`,
        "Order Status Report"
      );
    } catch (e) {
      console.log(e);
      Toast("Failed to generate report", e?.data?.message ?? "", "error");
    } finally {
    }
  };
  const onButtonClick = (e) => {
    e.preventDefault();
    // Show a toast when user is trying to download todays report
    // if (dayjs(selectedDate).isToday()) {
    //   Toast(
    //     "Possible Incomplete Report",
    //     "Note: Report could be incomplete as the day is not yet over.",
    //     "info"
    //   );
    // }
    onDownloadClick();
  };
  const CustomDatePicker = forwardRef(({ value, onClick, title }, ref) => (
    <Button
      size="sm"
      onClick={onClick}
      //   disabled={isLoading}
      ref={ref}
      leftIcon={<CalendarIcon />}
    >
      {title}: {value}
    </Button>
  ));

  // toast functionality ⏰
  const Toast = (title = "", desc = "", type) =>
    toast({
      title: title,
      description: desc,
      status: type,
      duration: 3000,
      isClosable: true,
    });

  return (
    <Flex direction="column">
      <Flex direction="column">
        {/* <Center> */}
        <Box width="185px" mr="20px" my="10px">
          <Badge variant="subtle" fontSize="16px" colorScheme={"twitter"}>
            ORDER STATUS REPORT
          </Badge>
        </Box>
        {/* </Center> */}
        <Flex
          width={"100%"}
          wrap="wrap"
          justifyContent="space-between"
          my="10px"
        >
          <Select
            variant="filled"
            // placeholder={sites?.length ? "Select site" : "No sites found"}
            defaultValue={selectedSiteID}
            value={selectedSiteID}
            onChange={(e) => {
              setselectedSiteID(e.target.value);
            }}
            size="sm"
            width="150px"
            borderRadius="5px"
            mr="20px"
            my="10px"
          >
            {getSitesFromPermissions().map((site) => (
              <option value={site} key={site}>
                {site}
              </option>
            ))}
            {/* <option value={"all"} key={"all"}>
              {`Site: All`}
            </option> */}
          </Select>
          <Flex my="10px" mr="20px">
            <Box my="10px" mr="20px">
              <DatePicker
                selected={startDate}
                maxDate={startDate > endDate ? endDate : new Date()}
                onChange={(date) => {
                  setstartDate(date);
                }}
                dateFormat="PP"
                title={"From"}
                customInput={<CustomDatePicker />}
                todayButton="Select today"
                showPopperArrow={false}
                popperPlacement="bottom"
                // disabled={isLoading}
              />
            </Box>
            <Box my="10px" mr="20px">
              <DatePicker
                selected={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={(date) => {
                  setendDate(date);
                }}
                dateFormat="PP"
                title={"To"}
                customInput={<CustomDatePicker />}
                todayButton="Select today"
                showPopperArrow={false}
                popperPlacement="bottom"
                // disabled={isLoading}
              />
            </Box>{" "}
          </Flex>
          <Button
            size="sm"
            isLoading={emptyLoading}
            disabled={emptyLoading || !selectedSiteID || !sites?.length}
            rightIcon={<DownloadIcon />}
            onClick={onButtonClick}
            my="10px"
          >
            Download
          </Button>
          {/* <Input
            placeholder="search"
            size="sm"
            my="10px"
            mr="20px"
            width="350px"
            onChange={(e) => searchFilter(e.target.value)}
          /> */}
        </Flex>
      </Flex>
      <LineChart chartData={chartData} height={"90px"} />
      <Flex overflowX="auto">
        <Table variant="simple" size="sm">
          <TableCaption placement={"top"}>
            {/* Sales Report */}
            {/* {showTableCaption ? tableCaption : ""} */}
          </TableCaption>
          <GeneralTableHead
            headers={tableHeaders}
            sortKey={sortKey}
            sort={handleSort}
          />
          <OrderStatusTable
            report={
              filterReport?.slice(
                (currentPage - 1) * rowToShow,
                currentPage * rowToShow
              ) ?? []
            }
            emptyLoading={emptyLoading}
          />
        </Table>
      </Flex>
      <Pagination
        page={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        rowToShow={rowToShow}
        setRowToShow={setRowToShow}
      />
    </Flex>
  );
}
export default OrdersAndCancellationReport;
