import React, { createContext, useState } from "react";

const initialAuthState = {
  authStateLoading: true,
  access_token: "",
};

export const AuthContext = createContext({
  authState: initialAuthState,
});

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState(initialAuthState);

  const updateAuthStateContext = (access_token) => {
    window.localStorage.setItem("auth", access_token);

    setAuthState({
      authStateLoading: false,
      access_token,
    });
  };
  /**
   * Remove access_token from local storage and logout user
   */
  const logOutUser = () => {
    if (window.localStorage.getItem("auth") !== null) {
      window.localStorage.removeItem("auth");
      window.localStorage.removeItem("refresh_token");
    }
    setAuthState({
      access_token: "",
      authStateLoading: false,
    });
  };

  const checkAuthState = () => {
    const access_token = window.localStorage.getItem("auth");
    const tokenGeneratedTime = parseInt(
      localStorage.getItem("token_gen_time"),
      10
    );

    if (
      access_token !== null &&
      tokenGeneratedTime &&
      tokenGeneratedTime + 1400 * 60 * 1000 >= new Date().getTime()
    ) {
      updateAuthStateContext(access_token);
    } else {
      logOutUser();
    }
  };
  const logInUser = (access_token) => {
    updateAuthStateContext(access_token);
  };

  const isLoggedIn = authState.access_token ? true : false;

  const value = {
    authState,
    checkAuthState,
    logInUser,
    logOutUser,
    isLoggedIn,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
