import { Tbody, Td, Text, Tr } from "@chakra-ui/react"; //Table Imports
import { CategoryPerformanceSkeleton } from "../components/TableSkeletons";
// import MapsLink from "../components/MapsLink";
// import ItemsTotal from "./ItemsTotal";
// import OrderDetails from "./OrderDetails";

const CategoryPerformanceTable = ({ emptyLoading, report }) => {
  return (
    <>
      {emptyLoading && <CategoryPerformanceSkeleton />}
      {!emptyLoading && report && (
        <Tbody>
          {report.map((item, index) => {
            const category_id = item?.category_id ?? "-";
            const name = item?.name ?? "-";
            const total_qty = item?.total_qty ?? "-";
            const total_sales = item?.total_sales ?? "-";

            return (
              <Tr key={`${category_id}-${index}`}>
                <Td minWidth={100}>
                  <Text>{category_id}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{name}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{total_qty}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{total_sales}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export default CategoryPerformanceTable;
