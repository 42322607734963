export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const setRefreshToken = (token) => {
  localStorage.setItem("refresh_token", token);
  return;
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refresh_token");
  return;
};

export const setUser = (u) => {
  localStorage.setItem("user", JSON.stringify(u));
  return;
};

export const getUser = () => {
  let user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

export const removeUser = () => {
  localStorage.removeItem("user");
  return;
};
