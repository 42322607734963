import React, { createContext, useState } from "react";
import { getWallets, getVehicleList, getDeliveryAgentList } from "../api";
import { useToast } from "@chakra-ui/react";
import constants from "../json/constants.json";

export const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [siteId, setSiteId] = useState("8042");
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState();
  const { rowsPerPage } = constants;

  const value = {
    siteId,
    setSiteId,
    offset,
    setOffset,
    page,
    setPage,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
