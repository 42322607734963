import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Table, TableCaption, Flex, useToast } from "@chakra-ui/react";
import dayjs from "dayjs";

import {
  AgentSummaryTableHead_v1,
  AgentOrdersTableHead_v1,
} from "../components/TableHeads";
import ReportHeader from "../components/ReportHeader";
import {
  AgentSummaryReportTable,
  AgentOrdersTable,
} from "../containers/AgentSummaryTable_v1";

import { exportToExcel } from "../utils/exportToExcel";

import { getEndOfDayReport, getSites } from "../api";
/**
 * End of the day report
 */
function AgentSummaryReport_v1(props) {
  const toast = useToast();
  const params = new URLSearchParams(useLocation().search);
  const selectID = params.get("name");
  const defaultSiteID = params.get("id");

  const [emptyLoading, setemptyLoading] = useState(false);
  const [report, setreport] = useState([]);
  const [tableData, settableData] = useState([]);
  // const [filterTable, setfiltertable] = useState([]);
  const [sites, setSites] = useState([]);
  const [tables, settables] = useState([]);
  const [selectedTableId, setselectedTableId] = useState("");

  useEffect(() => {
    const loadSites = async () => {
      try {
        const sites = await getSites();
        if (sites) {
          setSites(Object.values(sites));
        }
      } catch (e) {
        console.log(e);
        setSites([]);
      }
    };

    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (
    date = dayjs(new Date()).format("YYYY-MM-DD"),
    site = defaultSiteID
  ) => {
    let tables_array = [],
      table = [];
    setemptyLoading(true);
    const response = await getEndOfDayReport(site, date);
    setemptyLoading(false);
    setreport(response);
    // list of tables
    response.agents.forEach((agent) => {
      tables_array.push({
        Id: "agent-" + agent.agentId,
        name: agent.agentName,
      });
    });
    Object.keys(response.reports).forEach((key, index) => {
      tables_array.push({ Id: "report-" + key, name: key });
      // list of report in last index
      if (Object.keys(response.reports).length - 1 === index) {
        table = response.reports[key];
      }
    });
    settables(tables_array);
    setselectedTableId(tables_array[tables_array.length - 1]);

    settableData(table);
    // setfiltertable(table);
  };

  useEffect(() => {
    getData();
  }, []);
  console.log(tableData);
  const selectedTable = (id) => {
    let Id = id.split("-");
    setselectedTableId({ name: Id[0], id: Id[1] });
    let table = [];
    if (Id[0] === "report") {
      Object.keys(report.reports).forEach((key) => {
        if (key === Id[1]) {
          table = report.reports[key];
        }
      });
    } else {
      report.agents.forEach((agent) => {
        if (agent.agentId === Id[1]) {
          table = agent;
        }
      });
    }
    settableData(table);
    // setfiltertable(table);
  };
  const onDownloadClick = async (date, site) => {
    try {
      exportToExcel(
        "agent_v1",
        report,
        `AGENT-SUMMARY-${date}-${site}`,
        "AGENT-SUMMARY-REPORT"
      );
    } catch (e) {
      console.log(e);
      errorToast(e);
    } finally {
    }
  };

  //   const searchFilter = (value) => {
  //     if (selectedTableId === "report") {
  //       setfilterReport(
  //         report.filter((item) => {
  //           if (item.type !== "sum") {
  //             return item?.agentId
  //               .toLowerCase()
  //               .includes(value.toLowerCase().trim());
  //           }
  //         })
  //       );
  //     } else {
  //       setfiltertable(
  //         orders.filter((item) => {
  //           return item?.id.toString().includes(value.trim());
  //         })
  //       );
  //     }
  //   };

  const errorToast = (error) =>
    toast({
      title: "Failed to generate report",
      description: error?.data?.message ?? "Something went wrong",
      status: "error",
      duration: 2500,
      isClosable: true,
    });
  return (
    <Flex direction="column">
      <ReportHeader
        reportName={"End of the day report"}
        onDownloadClick={onDownloadClick}
        isLoading={emptyLoading}
        colorScheme="twitter"
        sites={sites}
        defaultSiteID={defaultSiteID}
        updateTable={getData}
        selectID={selectID}
        isSearchFilter={false}
        isMultipleTables={true}
        tables={tables}
        selectedTable={selectedTable}
        defaultTableId={
          // "report-16.07.2021"
          tables[tables.length - 1] ? tables[tables.length - 1].Id : ""
        }
      />
      <Flex overflowX="auto">
        <Table variant="simple" size="sm">
          <TableCaption placement={"top"}></TableCaption>
          {selectedTableId.name === "agent" ? (
            <>
              <AgentOrdersTableHead_v1 />
              <AgentOrdersTable
                report={tableData ?? []}
                emptyLoading={emptyLoading}
              />
            </>
          ) : (
            <>
              <AgentSummaryTableHead_v1 />
              <AgentSummaryReportTable
                report={tableData ?? []}
                emptyLoading={emptyLoading}
              />
            </>
          )}
        </Table>
      </Flex>
    </Flex>
  );
}

export default AgentSummaryReport_v1;
