import {
  Container,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Flex
} from "@chakra-ui/react"; //Table Imports

const SalesSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const AgentSummarySkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={300}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const AgentOrdersSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const SalesSummarySkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const LocationSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const TenderSalesDataSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const TenderSalesReportSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const ReconciliationLhsReportSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const ReconciliationRhsReportSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const ManageWalletsSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={70}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={150}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>{" "}
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>{" "}
      </Tr>
    ))}
  </Tbody>
);

const MissingReportSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const SearchResultSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={200}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const AddCashSkeleton = () => (
  <Stack>
    <Skeleton height="50px" />
    <Skeleton height="50px" />
    <Skeleton height="50px" />
  </Stack>
);
const CategoryPerformanceSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const TopSlowMovingProductsSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const OrderStatusSkeleton = () => (
  <Tbody>
    {[1, 2, 3].map(i => (
      <Tr key={i}>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
        <Td minWidth={100}>
          <Skeleton height="25px" />
        </Td>
      </Tr>
    ))}
  </Tbody>
);
const HubLevelSkeleton = () =>
  [1, 2].map(i => (
    <Flex justifyContent="space-between" key={i}>
      <Skeleton my="5px" minWidth={"25%"} height="25px" />
      <Skeleton my="5px" minWidth={"25%"} height="25px" />
      <Skeleton my="5px" minWidth={"25%"} height="25px" />
    </Flex>
  ));
export {
  SalesSkeleton,
  AgentSummarySkeleton,
  AgentOrdersSkeleton,
  SalesSummarySkeleton,
  LocationSkeleton,
  TenderSalesDataSkeleton,
  TenderSalesReportSkeleton,
  ReconciliationLhsReportSkeleton,
  ReconciliationRhsReportSkeleton,
  ManageWalletsSkeleton,
  AddCashSkeleton,
  MissingReportSkeleton,
  SearchResultSkeleton,
  CategoryPerformanceSkeleton,
  TopSlowMovingProductsSkeleton,
  OrderStatusSkeleton,
  HubLevelSkeleton
};
