import { ChakraProvider } from "@chakra-ui/react";
import RootRoute from "./routes/RootRoute";
import { AppContextProvider } from "./context/AppContext";
import { AuthContextProvider } from "./context/AuthContext";
import "./App.css";

function App() {
  return (
    <ChakraProvider>
      <AuthContextProvider>
        <AppContextProvider>
          <RootRoute />
        </AppContextProvider>
      </AuthContextProvider>
    </ChakraProvider>
  );
}

export default App;
