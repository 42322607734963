import { Button } from "@chakra-ui/button";
import { DownloadIcon } from "@chakra-ui/icons";
import { useRef } from "react";
import { CSVLink } from "react-csv";

const ExportCSV = ({ data = [], filename = "export" }) => {
  const csvLink = useRef();
  return (
    <>
      <Button
        onClick={() => csvLink.current.link.click()}
        rightIcon={<DownloadIcon />}
        size="sm"
      >
        Export to CSV
      </Button>
      <CSVLink
        data={data}
        filename={`${filename}.csv`}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

export default ExportCSV;
