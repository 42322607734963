/**
 * sorting list of objects with given key
 * @param {string} key key used for sorting
 * @param {string} type type of key (number || string || date)
 * @param {Array<object>} array list
 */
const sortList = (key, type, array) => {
  switch (type) {
    case "number":
      return array.sort(function (a, b) {
        if (parseFloat(a[key]) < parseFloat(b[key])) {
          return 1;
        }
        if (parseFloat(a[key]) > parseFloat(b[key])) {
          return -1;
        }
        return 0;
      });
    case "string":
      return array.sort(function (a, b) {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
    case "date":
      return array.sort(function (a, b) {
        if (new Date(a[key]) < new Date(b[key])) {
          return 1;
        }
        if (new Date(a[key]) > new Date(b[key])) {
          return -1;
        }
        return 0;
      });
    default:
      return array.sort(function (a, b) {
        if (parseFloat(a[key]) < parseFloat(b[key])) {
          return 1;
        }
        if (parseFloat(a[key]) > parseFloat(b[key])) {
          return -1;
        }
        return 0;
      });
  }
};
export default sortList;
