import { Tbody, Td, Text, Tr } from "@chakra-ui/react"; //Table Imports
import { LocationSkeleton } from "../components/TableSkeletons";

const LocationTable = ({ emptyLoading, report }) => {
  return (
    <>
      {emptyLoading && <LocationSkeleton />}
      {!emptyLoading && report && (
        <Tbody>
          {report.map((item, index) => {
            const id = item?.order_id ?? "-";
            const name = item?.name ?? "-";
            const email = item?.email ?? "-";
            const emirate = item?.emirate ?? "-";
            const delivered_time = item?.delivered_time ?? "-";
            const latitude = item?.latitude ?? "-";
            const longitude = item?.longitude ?? "-";
            const timeslot = item?.timeslot ?? "-";
            return (
              <Tr key={`${id}-${index}`}>
                <Td minWidth={150}>
                  <Text>{id}</Text>
                </Td>
                <Td minWidth={150}>
                  <Text>{name}</Text>
                </Td>
                <Td minWidth={200}>
                  <Text>{email}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{emirate}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{delivered_time}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{latitude}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{longitude}</Text>
                </Td>
                <Td minWidth={100}>
                  <Text>{timeslot}</Text>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export default LocationTable;
