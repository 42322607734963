import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./doughnutchart.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";

export default function DoughnutChart({
  data = [0, 0, 0],
  labels = [],
  islegandVisible = true,
  color = [],
}) {
  let sumDataset = 0;

  data.forEach((i) => {
    sumDataset = sumDataset + i;
  });

  let doughnut = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: color,
      },
    ],
  };
  const sumTotal = () => {};

  const chartOptions = {
    plugins: {
      tooltips: {
        backgroundColor: "#f7f7f7",
        bodyFontColor: "#000000",
        cornerRadius: 4,
        callbacks: {
          label: function (tooltipItem, data) {
            return data["datasets"][0]["data"][tooltipItem["index"]];
          },
        },
      },
      cutoutPercentage: 75,
      responsive: true,
      legend: {
        display: islegandVisible,
        position: "bottom",
        // align: "start",
        labels: {
          boxWidth: 14,
          fontSize: 14,
          padding: 15,
          fontColor: "black",
          // fontFamily: "Quicksand",
        },
        onClick: sumTotal,
      },
      elements: {
        center: {
          text: sumDataset,
        },
      },
    },
  };

  return (
    <div className="doughnutchart">
      {/* <div className="doughnutchart-title">ORDERS</div> */}
      <div style={{ marginTop: "40px", height: "100%" }}>
        {/* <div className="doughtnut-center">
          <span>Total</span>
          <span>{sumDataset}</span>
        </div> */}

        <Doughnut
          data={doughnut}
          width={200}
          height={260}
          options={chartOptions}
        />
      </div>
    </div>
  );
}
