import React from "react";
import { Td, Tbody, Tr, Text, Flex } from "@chakra-ui/react";

const CouponReportTable = ({ data }) => {
  return (
    <Tbody>
      {data?.map((item, key) => {
        return (
          <Tr key={key}>
            <Td>{item?.coupon_code.toUpperCase()} </Td>
            <Td>{`AED ${item?.coupon_discount_amount}`}</Td>
            <Td>{item?.order_count}</Td>
            <Td>{`AED ${item?.total_discount_amount}`}</Td>
            <Td>{`AED ${item?.total_order_value}`}</Td>
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default CouponReportTable;
