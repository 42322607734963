import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Box, useToast, Center, Progress } from "@chakra-ui/react";
import { AuthContext } from "../context/AuthContext";
import Header from "../containers/Header";
import Home from "../screens/Home";
import Login from "../screens/Login";
import SalesReport from "../screens/SalesReport";
import CouponReport from "../screens/CouponReport";
import LocationReport from "../screens/LocationReport";
import AgentSummaryReport_v1 from "../screens/AgentSummaryReport_v1";
import TotalReport from "../screens/TotalReport";
import KPIStats from "../screens/KPIStats";
import NOBReport from "../screens/NOBReport";
import OrderTimelineReport from "../screens/OrderTimelineReport";
import CategoryPerformanceReport from "../screens/CategoryPerformanceReport";
import TopAndSlowMovingProducts from "../screens/TopAndSlowMovingProducts";
import OrdersAndCancellationReport from "../screens/OrdersAndCancellationReport";
import StockMovementReport from "../screens/StockMovementReport";
import HubLevelReport from "../screens/HubLevelReport";
import { getRefreshToken, removeRefreshToken, setUser } from "../utils/token";
import axios from "axios";
import { setupAxios } from "../utils/setupAxios";

const RootRoute = () => {
  const { isLoggedIn, logInUser, logOutUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  setupAxios(axios, toast, logOutUser);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        let token = getRefreshToken();
        if (token) {
          const result = await axios.post(
            `https://ums-stag.nesto.shop/api/v1/admin/token`,
            { refreshToken: getRefreshToken() }
          );
          const permissions = await axios.get(
            `https://ums-stag.nesto.shop/api/v1/admin/user-access-permission`,
            { headers: { authorization: `Bearer ${result.data.data.token}` } }
          );
          const hasAccess = permissions.data.data.filter(
            (p) => p.code === "MIS_ACCESS"
          )[0].is_read;
          if (!hasAccess) {
            setLoading(false);
            logOutUser();
          }
          setLoading(false);
          setUser(permissions.data.data);
          logInUser(result.data.data.token);
        } else {
          removeRefreshToken();
          setLoading(false);
          logOutUser();
        }
      } catch (err) {
        toast({
          description: err.response?.data?.message || "An error occurred",
          status: "error",
        });
        removeRefreshToken();
        setLoading(false);
        logOutUser();
      }
      setLoading(false);
    };
    refreshToken();
  }, [toast]);

  if (loading) {
    return (
      <Box px={10}>
        <Center sx={{ height: "100vh" }}>
          <Progress
            mt={100}
            width={500}
            size="xs"
            isIndeterminate
            colorScheme="green"
          />
        </Center>
      </Box>
    );
  }

  return (
    <Router>
      <Box px="25px" pt="80px" pb="40px">
        {isLoggedIn ? (
          <>
            <Header />
            <Switch>
              <Route exact path="/sales-report" component={SalesReport} />
              <Route
                exact
                path="/eod-report"
                component={AgentSummaryReport_v1}
              />
              <Route exact path="/location-report" component={LocationReport} />

              <Route exact path="/nob-report" component={NOBReport} />
              <Route exact path="/promotion-report" component={CouponReport} />
              <Route
                exact
                path="/order-timeline-report"
                component={OrderTimelineReport}
              />
              <Route
                exact
                path="/stock-movement-report"
                component={StockMovementReport}
              />
              <Route
                exact
                path="/total_reports/private"
                component={TotalReport}
              />
              <Route exact path="/kpi-stats" component={KPIStats} />
              <Route
                exact
                path="/category-performance"
                component={CategoryPerformanceReport}
              />
              <Route
                exact
                path="/top-slow-moving-products"
                component={TopAndSlowMovingProducts}
              />
              <Route
                exact
                path="/orders-cancellation"
                component={OrdersAndCancellationReport}
              />
              <Route exact path="/hub-level" component={HubLevelReport} />
              <Route exact path="/" component={Home} />
              <Redirect to="/" />
            </Switch>
          </>
        ) : (
          <Switch>
            <Route component={Login} />
          </Switch>
        )}
      </Box>
    </Router>
  );
};

export default RootRoute;
